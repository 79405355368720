<template>
  <div>
    <div class="maintitle">订单统计</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-select v-model="crntdistrict" placeholder="请选择地区" style="width:120px;">
        <el-option
          v-for="(item,index) in district"
          :key="index"
          :label="item.fullname"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-date-picker
        v-model="timerange"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :default-time="['00:00:00', '23:59:59']"
        style="width:250px;"
        class="margin-big-left"
      ></el-date-picker>
      <el-button type="primary" @click="srch('diy')" class="margin-left">搜索</el-button>
    </el-row>
    <el-table :data="list" border style="width:800px">
      <el-table-column fixed prop="goodstype_text" label="类型"></el-table-column>
      <el-table-column prop="weight" label="重量(公斤)" width="200"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //业务人员列表
      district: [],
      crntdistrict: "",
      timerange: [this.getdate("start"), this.getdate("end")], //查找时间范围，默认为本月
    };
  },
  computed: {},
  methods: {
    getlist: function() {
      let that = this;
      this.$axios({
        url: this.HOST + "/jiameng/order/getStatisstics",
        method: "post",
        data: {
          district:this.crntdistrict,
          timerange:this.timerange
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.list = res.data.list;
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    getdate(str) {
      let date = new Date();
      if (str == "start") {
        return (
          date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + "01 00:00:00"
        );
      } else {
        return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate() +
          " 23:59:59"
        );
      }
    },
    getAreaList() {
      let url = this.HOST + "/jiameng/index/getAreaList";
      this.$axios({
        method: "post",
        url: url,
        data: {}
      }).then(res => {
        if (res.data.status == 1) {
          this.district = res.data.list;
        } else {
          return [];
        }
      });
    },
    srch(str) {
      this.srchtype = str=='diy'?this.timerange:str;
      this.page = 1;
      this.getlist();
    },
  },
  created: function() {
    this.getlist();
    this.getAreaList();
  }
};
</script>

<style>
</style>